import { Fragment, ReactNode } from 'react';

export default function ConditionalRenderer({
  condition,
  children,
  fallback,
}: {
  condition: boolean | any;
  children: ReactNode;
  fallback?: JSX.Element | JSX.Element[];
}) {
  if (condition) {
    return <Fragment>{children}</Fragment>;
  } else return <Fragment>{fallback}</Fragment>;
}
