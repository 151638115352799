import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingIcon } from '../../icons';
import ConditionalWrapper from '../ConditionalWrapper';
import { twJoin, twMerge } from 'tailwind-merge';

type ButtonColor = 'gradient' | 'neutral' | 'secondary' | 'warning' | 'success';

// TODO: define if use text or children
interface RoundedButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: ButtonColor;
  text?: string;
  children?: React.ReactNode;
  className?: string;
  loading?: boolean | false;
  disabled?: boolean;
  href?: string;
  size?: string;
  isExternalLink?: boolean;
  testId?: string;
  download?: boolean;
}

const buttonColorClasses: Record<ButtonColor, string> = {
  gradient:
    'bg-gradient-to-r from-secondary to-primary enabled:hover:from-secondary/40 enabled:hover:to-primary/40 disabled:from-secondary-content disabled:to-primary-content focus-visible:ring-secondary/40',
  secondary:
    'bg-secondary enabled:hover:bg-secondary-content disabled:bg-secondary-content focus-visible:ring-secondary/40',
  neutral:
    'bg-neutral enabled:hover:bg-neutral/50 disabled:bg-neutral-content focus-visible:ring-neutral/50',
  success: 'bg-success disabled:bg-success enabled:hover:bg-success',
  warning:
    'bg-error enabled:hover:bg-error-content disabled:bg-error-content focus-visible:ring-error-content',
};

export default function RoundedButton(props: RoundedButtonProps) {
  const {
    disabled,
    loading,
    color,
    className,
    text,
    children,
    href,
    size,
    isExternalLink,
    testId,
    type = 'submit',
    download,
    ...rest
  } = props;

  function Button() {
    return (
      <button
        {...rest}
        type={type}
        data-testid={testId}
        disabled={disabled || loading || false}
        className={twMerge(
          `px-3.5 md:px-8 lg:px-12 py-2 ${
            size || 'text-12 md:text-16'
          } items-center leading-tight flex rounded-full gap-2.5 text-base-100 justify-center font-poppins font-600 enabled:active:scale-95 focus-visible:ring focus:outline-none duration-150 transition-all disabled:cursor-default`,
          buttonColorClasses[color || 'gradient'],
          className,
        )}
      >
        <div
          className={`w-3 h-3 md:w-4 md:h-4 duration-150 transition-all shrink-0 ${
            loading ? '' : 'hidden'
          }`}
        >
          <LoadingIcon />
        </div>
        {children || text}
      </button>
    );
  }

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={
        <Link
          download={download}
          data-testid={testId + 'Href'}
          className={twJoin('flex text-base-100', disabled ? 'disabled' : '')}
          to={href ?? ''}
          target={isExternalLink ? '_blank' : undefined}
        />
      }
    >
      <Button />
    </ConditionalWrapper>
  );
}
