import { fadeIn } from '@/utils/animations/commom';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Lottie from 'react-lottie';

interface LottieAnimationProps {
  className?: string;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  size?: string;
  src: string | object;
}

export default function LottieAnimation({
  className,
  setIsVisible,
  src,
  size,
}: LottieAnimationProps) {
  return (
    <AnimatePresence>
      <motion.div className={className || ''} {...fadeIn}>
        <Lottie
          eventListeners={[
            {
              eventName: 'complete',
              callback: () => setIsVisible?.(false),
            },
          ]}
          options={{
            rendererSettings: {
              className: size || 'w-52 mx-auto',
            },
            autoplay: true,
            loop: false,
            animationData: src,
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
}
