import React, { PropsWithChildren } from 'react';
import Skeleton from '../Skeleton';
import Tag from './Tag';
import { Coin, Gem } from '@/components/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';
import LottieAnimation from './LottieAnimation';
import useStudentContext from '@/data/hook/student';
import Rewards from '@/models/Rewards';
import useAuth from '@/data/hook/useAuth';
import { isStaff, isStudent } from '@/functions/auth';
import ConditionalRenderer from '../ConditionalRenderer';
import { UserTypeEnum } from '@/models/User';
import ComponentGuard from '../ComponentGuard';
import { calculateLevel } from '@/utils/xp/levelHandler';
import Text from './Text';
import { motion } from 'framer-motion';
import coinAnimation from '@/assets/video/lottie/coin-animation.json';

export const RenderRewards = ({
  rewardsData,
  isFetchingRewards,
  size = 'regular',
}: {
  rewardsData?: Rewards;
  isFetchingRewards?: boolean;
  size?: 'regular' | 'small';
}) => {
  const xp = rewardsData?.xp ?? 0;
  const levelCounter = calculateLevel(xp);
  const { t } = useTranslation('translation', { keyPrefix: 'rewardsView' });

  const { user } = useAuth();
  const defaultClassName =
    'flex px-2 py-0.5 rounded-full text-base-100 font-500 flex-row-reverse gap-2 hover:ring transition ease-in-out duration-300';

  const isUserStudent = isStudent(user?.userType);

  if (isFetchingRewards) {
    return <Skeleton className="h-6 w-[10%] bg-primary-content rounded-md" />;
  }

  return (
    <div className="flex gap-4 h-fit shrink" data-testid="rewardsView">
      <TagContainer>
        <ComponentGuard roles={[UserTypeEnum.STUDENT]}>
          <AnimatedReward />
        </ComponentGuard>
        <ComponentGuard roles={[UserTypeEnum.STUDENT]}>
          <Tooltip text={isUserStudent ? t('xpTooltip') : ''} className="w-52">
            <div
              data-testid="xpTagText"
              className={`ring-info-content bg-info/55 text-white flex flex-col items-center rounded-full overflow-hidden w-fit relative px-4 ${
                size === 'small' ? 'py-0.5' : 'py-1'
              }`}
            >
              <Text
                format="rubik-500"
                className="mb-0.5"
                text={t('level', { lvl: levelCounter.level })}
              />
              <div
                className={`w-[110%] absolute bottom-0 rounded-full bg-info-content ${
                  size === 'small' ? 'h-0.5' : 'h-1'
                }`}
              >
                <motion.div
                  className="bg-info h-full rounded-full"
                  animate={{
                    width: `${(levelCounter.percentage ?? 0) * 100}%`,
                  }}
                />
              </div>
            </div>
          </Tooltip>
        </ComponentGuard>
        <TagContainer>
          <Tooltip
            text={isUserStudent ? t('coinTooltip') : ''}
            className="w-52"
          >
            <Tag
              color="custom"
              testId="coinTag"
              icon={
                <Coin
                  className={size === 'small' ? 'w-5 h-5' : 'w-6 h-6'}
                  data-testid="coinIcon"
                />
              }
              text={
                rewardsData ? Math.round(rewardsData.coins).toString() : 'Ꝏ'
              }
              className={`${defaultClassName} bg-yellow-400 ring-yellow-100`}
            />
          </Tooltip>
          <Tooltip text={isUserStudent ? t('gemTooltip') : ''} className="w-52">
            <Tag
              color="custom"
              testId="gemTag"
              icon={
                <Gem
                  className={size === 'small' ? 'w-5 h-5' : 'w-6 h-6'}
                  data-testid="gemIcon"
                />
              }
              text={rewardsData ? Math.round(rewardsData.gems).toString() : 'Ꝏ'}
              className={`${defaultClassName} bg-success ring-success-content`}
            />
          </Tooltip>
        </TagContainer>
      </TagContainer>
    </div>
  );
};

const TagContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex flex-row items-center justify-end flex-wrap-reverse gap-2">
      {children}
    </div>
  );
};

export type RewardsViewProps = {
  size?: 'regular' | 'small';
};

export const RewardsView = ({ size }: RewardsViewProps) => {
  const { rewardsData, isLoadingRewards } = useStudentContext();
  const { user } = useAuth();

  if (isLoadingRewards) {
    return (
      <Skeleton
        className="w-32 h-7 bg-primary-content rounded-full"
        testId="rewardsSkeleton"
      />
    );
  } else if (rewardsData) {
    return <RenderRewards size={size} rewardsData={rewardsData} />;
  } else if (user && isStaff(user.userType)) {
    return <RenderRewards size={size} />;
  } else {
    return <React.Fragment />;
  }
};

const AnimatedReward = () => {
  const { shouldAnimateReward } = useStudentContext();
  return (
    <ConditionalRenderer condition={shouldAnimateReward}>
      <LottieAnimation
        className="fixed bottom-3.5 right-5 w-fit h-fit z-[70]"
        size="w-[4.5rem]"
        src={coinAnimation}
      />
    </ConditionalRenderer>
  );
};
